import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'gatsby';
import './header.scss';
import classNames from 'classnames';
import { useLocation } from "@reach/router"
import menuImage from '../images/menu.svg';
import closeImage from '../images/close.svg';

function NavMenu() {
    return <nav>
        <ul>
            <li>
                <Link to="/" activeClassName='active'>Home</Link>
            </li>
            <li>
                <Link to="/explore" activeClassName='active'>Explore the data</Link>
            </li>
            <li>
                <Link to="/about" activeClassName='active'>About</Link>
            </li>
        </ul>
    </nav>
}

export default function Header({ hideNav }: { hideNav?: boolean }) {
    const location = useLocation();
    const [scrollY, setScrollY] = useState(0);
    const isHomepage = location.pathname === '/';
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        if (typeof window === 'undefined' || !isHomepage) {
            return;
        }
        setScrollY(window.scrollY);

        const onScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    const headerClasses = classNames({
        'top-header': true,
        active: scrollY > 50,
        homepage: isHomepage,
        'hide-nav': hideNav
    });

    return <div className={headerClasses}>
        <div className="wrapper">
            <Link to="/">Dynamic World</Link>
            {!hideNav && <>
              <NavMenu/>
                {!menuOpen && <div className="open-mobile-menu"
                                   onClick={() => setMenuOpen(true)}>
                  <img src={menuImage}/>
                </div>}
            </>}
        </div>
        {menuOpen && <div className="mobile-menu">
          <div className="close-mobile-menu"
               onClick={() => setMenuOpen(false)}>
            <img src={closeImage}/>
          </div>
          <NavMenu/>
        </div>}
    </div>
}